let timeGet = (date) => {
  var date = new Date(date);
  var N = date.getFullYear() + "-";
  var M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1) + "-"
      : date.getMonth() + 1 + "-";
  var D = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  // var h =
  //   date.getHours() < 10
  //     ? "0" + date.getHours() + ":"
  //     : date.getHours() + ":";
  // var min =
  //   date.getMinutes() < 10
  //     ? "0" + date.getMinutes() + ":"
  //     : date.getMinutes() + ":";
  // var sec =
  //   date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  // var week = date.getDay();
  // if (week == 1) {
  //   this.week = "周一";
  // } else if (week == 2) {
  //   this.week = "周二";
  // } else if (week == 3) {
  //   this.week = "周三";
  // } else if (week == 4) {
  //   this.week = "周四";
  // } else if (week == 5) {
  //   this.week = "周五";
  // } else if (week == 6) {
  //   this.week = "周六";
  // } else if (week == 7) {
  //   this.week = "周日";
  // }
  let day = N + M + D;
  // let strDate = h + min + sec;
  // this.date = day;
  // this.strDate = strDate;
  return day;
};
export default timeGet;
